import styles from './styles.module.scss'
import imagem from '../../assets/contato.png'
import {useState} from 'react'
import emailjs from 'emailjs-com';

// biblioteca toastify

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contato() {

    const showToast = (message, type = 'success') => {
        toast[type](message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      };
    
      const [nome, setNome] = useState('');
      const [email, setEmail] = useState('');
      const [celular, setCelular] = useState('');
      const [motivo, setMotivo] = useState('');
      const [descricao, setDescricao] = useState('');
    
      const enviarEmail = async (e) => {
        e.preventDefault();
    
        // Verificar se todos os campos estão preenchidos
        if (!nome || !email || !celular || !motivo || !descricao) {
          showToast('Por favor, preencha todos os campos corretamente.', 'error');
          return; // Abortar o envio do email se algum campo estiver vazio
        }
    
        // Configuração do serviço, template e usuário do emailjs
        const serviceId = 'service_g6hx5i4';
        const templateId = 'template_5qh1ash';
        const userId = '6csF6Q2a33s-R_a1B';
    
        try {
          const response = await emailjs.send(
            serviceId,
            templateId,
            {
              nome,
              email,
              celular,
              motivo,
              descricao,
            },
            userId
          );
    
          console.log('Email enviado com sucesso!', response);
          showToast('Email enviado com sucesso!');
            setNome('');
            setEmail('');
            setCelular('');
            setMotivo('');
            setDescricao('');
        } catch (error) {
          console.error('Erro ao enviar o email:', error);
          showToast('Erro ao enviar o email.', 'error');
        }
      };

    return(
        <div className = {styles.container} id = 'contato'>
            <div  className = {styles.imagem}>
                <img src = {imagem} alt = 'Imagem' className = {imagem}></img>
            </div>
            <div className = {styles.formulario}>
                <form className = {styles.textoFormulario} onSubmit = {enviarEmail}>
                    <h1 className = {styles.tituloForm}>Entre em contato</h1>

                    <label htmlFor = 'nome' className = {styles.labelForm}>Nome: </label>
                    <input className = {styles.inputForm} type = 'text' value = {nome} onChange={(e) => setNome(e.target.value)} required />

                    <label htmlFor = 'email' className = {styles.labelForm}>Email: </label>
                    <input className = {styles.inputForm} type = 'email' value = {email} onChange={(e) => setEmail(e.target.value)} required />

                    <label htmlFor = 'celular' className = {styles.labelForm}>Celular: </label>
                    <input className = {styles.inputForm} type = 'number' value = {celular} onChange={(e) => setCelular(e.target.value)} required />

                    <label htmlFor = 'motivo' className = {styles.labelForm}>Motivo do contato: </label>
                    <input className = {styles.inputForm} type = 'text' value = {motivo} onChange={(e) => setMotivo(e.target.value)} required />

                    <label htmlFor = 'descricao' className = {styles.labelForm}>Descrição do motivo de contato: </label>
                    <textarea className = {styles.tareaForm} value = {descricao} onChange={(e) => setDescricao(e.target.value)} required />

                    <button className = {styles.botaoForm} type = 'submit' onClick={showToast}>Enviar</button>
                    <ToastContainer />
                </form>
            </div>
        </div>
    )
}


export default Contato