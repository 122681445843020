import React from "react";

function Insta() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#FF4545"
        d="M12.501 0c-3.395 0-3.82.015-5.154.076-1.33.06-2.24.271-3.034.58A6.12 6.12 0 002.1 2.098 6.136 6.136 0 00.656 4.312c-.31.795-.52 1.703-.58 3.034C.015 8.679 0 9.106 0 12.5c0 3.395.015 3.82.076 5.153.06 1.33.271 2.24.58 3.034.32.822.747 1.52 1.442 2.214a6.121 6.121 0 002.213 1.443c.796.31 1.704.52 3.035.58 1.333.061 1.759.076 5.154.076s3.82-.015 5.153-.075c1.33-.061 2.24-.272 3.035-.581a6.116 6.116 0 002.213-1.443 6.136 6.136 0 001.443-2.214c.307-.795.518-1.704.58-3.034.06-1.333.076-1.758.076-5.153 0-3.395-.016-3.82-.076-5.154-.062-1.33-.273-2.24-.58-3.034A6.135 6.135 0 0022.9 2.098 6.109 6.109 0 0020.688.656c-.797-.309-1.706-.52-3.037-.58C16.318.015 15.893 0 12.497 0h.004zM11.38 2.253H12.5c3.338 0 3.733.012 5.051.071 1.219.056 1.88.26 2.321.431.583.227 1 .497 1.437.935.437.437.708.854.935 1.437.171.44.375 1.102.43 2.32.06 1.318.073 1.714.073 5.05s-.013 3.732-.073 5.05c-.055 1.219-.259 1.88-.43 2.32a3.864 3.864 0 01-.935 1.436 3.866 3.866 0 01-1.437.935c-.44.172-1.102.375-2.32.43-1.319.06-1.714.074-5.052.074-3.338 0-3.733-.013-5.05-.073-1.22-.056-1.881-.26-2.322-.431-.583-.227-1-.498-1.438-.935a3.874 3.874 0 01-.935-1.437c-.171-.44-.375-1.101-.43-2.32-.06-1.318-.072-1.714-.072-5.052 0-3.338.012-3.732.072-5.05.055-1.218.259-1.88.43-2.32.227-.584.498-1 .935-1.438a3.88 3.88 0 011.438-.935c.44-.172 1.102-.375 2.321-.431 1.153-.052 1.6-.068 3.93-.07v.003zm7.794 2.075a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM12.5 6.081a6.42 6.42 0 10.001 12.839 6.42 6.42 0 00-.001-12.84zm0 2.252a4.167 4.167 0 110 8.334 4.167 4.167 0 010-8.334z"
      ></path>
    </svg>
  );
}

export default Insta;