import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './estilo.css';

// import required modules
import { Navigation } from 'swiper/modules';


// imagens

import caseFIA from '../../assets/cases/caseFIA.png';
import caseFinance from '../../assets/cases/caseFinance.png';
import caseTaubate from '../../assets/cases/caseTaubate.png';
import caseONG from '../../assets/cases/caseONG.png';

export default function Cases() {

  AOS.init();

    return (
        <div data-aos="fade-up">
            <div className="title">
                <h1>Cases de Sucesso</h1>
            </div>

            <div className="slides">

            <>

      <Swiper 
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:3
        },
      }}
      style={{
        "--swiper-navigation-color": "white",
        "--swiper-navigation-size": "2rem",
      }}
      loop={true}
      navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
            <img src={caseFIA} alt="Case 1" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={caseONG} alt="Case 2" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={caseFinance} alt="Case 3" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={caseTaubate} alt="Case 4" />
        </SwiperSlide>
      </Swiper>
      
    </>
 

            </div>

        </div>
    );
}