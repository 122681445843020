import styles from './styles.module.scss';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// import icons

import icone1 from './asssets/processo/circle1.svg'
import icone2 from './asssets/processo/circle2.svg'
import icone3 from './asssets/processo/circle3.svg'
import icone4 from './asssets/processo/circle4.svg'
import icone5 from './asssets/processo/circle5.svg'
import calendario from './asssets/processo/calendar.svg'

function Processo() {

    AOS.init();
  return (
    <div className={styles.container} data-aos="fade-up">
      <h1 id={styles.titulo}>Nosso processo seletivo</h1>

        <h1 className={styles.subtitulo}>Conheça as etapas do processo:</h1>

        <div className={styles.item}>
            <img src = {icone1} alt = 'Imagem 1' className = {styles.imagem}></img>
            <div className={styles.topics}>
            <h3 className={styles.texto}>Inscrição</h3>
            <p>
              A inscrição para o processo seletivo é feita através do preenchimento de um formulário online.
            </p>
            </div>
        </div>

        <div className={styles.item}>
            <img src = {icone2} alt = 'Imagem 2' className = {styles.imagem}></img>
            <div className={styles.topics}>
            <h3 className={styles.texto}>Roda de conversa</h3>
            <p>
              A roda de conversa é um momento para que os candidatos possam conhecer mais sobre a empresa e tirar dúvidas.
            </p>
            </div>
        </div>

        <div className={styles.item}>
            <img src = {icone3} alt = 'Imagem 2' className = {styles.imagem}></img>
            <div className={styles.topics}>
            <h3 className={styles.texto}>Formulário avaliativo</h3>
            <p>
              O formulário avaliativo é uma etapa de autoconhecimento e reflexão sobre a trajetória do candidato.
            </p>
            </div> 
        </div>

        <div className={styles.item}>
            <img src = {icone4} alt = 'Imagem 2' className = {styles.imagem}></img>
            <div className={styles.topics}>
            <h3 className={styles.texto}>Dinâmica</h3>
            <p>
              A dinâmica é um momento para que os candidatos possam demonstrar sua capacidade de trabalho em equipe e pensamento crítico.
            </p>
            </div>
        </div>

        <div className={styles.item}>
            <img src = {icone5} alt = 'Imagem 2' className = {styles.imagem}></img>
            <div className={styles.topics}>
            <h3 className={styles.texto}>Entrevista</h3>
            <p>
              A entrevista é um momento para que possamos conhecer sobre as motivações e expectativas do candidato. Também faremos um alinhamento com a cultura da empresa.
            </p>
            </div>
        </div>


        <h1 className={styles.subtitulo}>Quando?</h1>
        <div id = 'interest'>
    
        </div>
        <div className={styles.item}>
            <img src = {calendario} alt = 'Calendario' className = {styles.imagem}></img>
            <div className={styles.topics}> 
            <h3 className={styles.header3}>Fevereiro - 2024</h3>
            </div>
        </div>
 
      </div>
  );
}

export default Processo;
