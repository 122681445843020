import Areas from "../../components/areasinternas/Areas";
import Processo from "../../components/processo/Processo";
import Interest from "../../components/interest/interest";
import Porquee from "../../components/porquee/Porquee";
import Team from "../../components/ourTeam/team";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar"
import styles from './styles.module.scss'


function Students() {
    return (
      <div className={styles.students}>

        <div className={styles.navPai}>
          <Navbar></Navbar>
        </div>
        <div className={styles.homeContent}>

        <Areas></Areas>
        <Porquee></Porquee>
        <Team></Team>
        <Processo></Processo>
        <Interest></Interest>
        <div className={styles.footerPai}>
          <Footer></Footer>
        </div>
        </div>
      </div>
    );
  }
  
  export default Students;