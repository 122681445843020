import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./global.scss" 

import { createRoot } from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Students from './pages/Students/Students';
import Home from './pages/Home/Home';

const rootElement = document.getElementById('root');

const renderApp = () => {
  createRoot(rootElement).render(
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/Home" element={<Home />} />
          <Route path="/Students" element={<Students />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

renderApp();