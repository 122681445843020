import styles from './styles.module.scss'
import IJLogo from '../../assets/IJBW.jsx'
import Insta from '../../assets/insta.jsx'
import Lkdn from '../../assets/lkdn.jsx'


function Footer() {
    return (
      <div className={styles.footer}>
        
        <div className={styles.modules}>
          <IJLogo></IJLogo>
        </div>
        <div className={styles.modules}>
          <h3>Sobre nós</h3>
          <p>
            Temos como principal objetivo trazer soluções tecnológicas para outras empresas em um processo de 
            desenvolvimento mútuo entre cliente e empresa, com a finalidade de desenvolver a melhor solução possível visando um 
            objetivo em comum: o impacto social.
          </p>
        </div>
        <div className={styles.modules}>
          <h3>Endereço e Contato</h3>
          <p>Av. Professor Almeida Prado, 520</p>
          <p>Butantã, 05508-901</p>
          <p>São Paulo – SP – Brasil</p>
          <p>E-mal: intelijr@gmail.com</p>
        </div>
        <div className={styles.modules}>
          <h3>Redes Sociais</h3>
          <div className={styles.footerIcons}>
          <a href="https://www.linkedin.com/company/inteli-júnior/posts/?feedView=all" target="_blank" rel="instagram_link">
            <Lkdn></Lkdn>
          </a>
          <a href="https://www.instagram.com/inteli.jr/" target="_blank" rel="linkedin_link">
            <Insta></Insta>
          </a>
            
          </div>
        </div>
      </div>
    );
  }
  
  export default Footer;