import React from "react";

function Lkdn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#FF4545"
        d="M0 1.79C0 .803.822 0 1.836 0h21.328C24.178 0 25 .802 25 1.79v21.42c0 .988-.822 1.79-1.836 1.79H1.836C.822 25 0 24.198 0 23.21V1.79zm7.724 19.138V9.638H3.972v11.29h3.752zM5.848 8.098c1.308 0 2.123-.867 2.123-1.95-.025-1.109-.815-1.951-2.098-1.951-1.284 0-2.123.842-2.123 1.95 0 1.084.814 1.95 2.073 1.95h.025zm7.67 12.83v-6.305c0-.337.024-.674.123-.915.271-.674.889-1.373 1.925-1.373 1.358 0 1.901 1.036 1.901 2.553v6.04h3.752v-6.473c0-3.468-1.85-5.081-4.32-5.081-1.99 0-2.882 1.094-3.381 1.863v.039h-.025l.025-.039V9.639H9.766c.049 1.059 0 11.289 0 11.289h3.752z"
      ></path>
    </svg>
  );
}

export default Lkdn;