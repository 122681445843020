import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Navigation } from 'swiper/modules';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// imagens

import people from '../../assets/people.JPG';
import jean from '../../assets/funcionarios2024/Jean.jpg';
import heitor from '../../assets/funcionarios2024/Heitor.jpg';
import clara from '../../assets/funcionarios2024/Clara.jpg';
import matheus from '../../assets/funcionarios2024/Matheus.jpg';
import pelinsari from '../../assets/funcionarios2024/Pelinsari.jpg';
import eduarda from '../../assets/funcionarios2024/Eduarda.jpg';
import otto from '../../assets/funcionarios2024/Otto.png';
import marco from '../../assets/funcionarios2024/Marco.jpg';
import clarinha from '../../assets/funcionarios2024/Clarinha.jpg';
import goulart from '../../assets/funcionarios2024/goulart.jpg';
import lucas from '../../assets/funcionarios2024/Lucas.jpg';
import luigi from '../../assets/funcionarios2024/Luigi.jpg';
import cecilia from '../../assets/funcionarios2024/Cecilia.jpg';
import antonio from '../../assets/funcionarios2024/Antonio.png';

export default function Team() {

  AOS.init();
  return (
    <div data-aos="fade-up">
    <div className="corpo">
      <div className="title">
        <h1 id = "title">Conheça nossa equipe</h1>
      </div>
      <div className="title">
        <h2>Diretoria</h2>
      </div>
      <div>
      <>
      <Swiper 
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 3,
        },
        865:{
          slidesPerView:4
        },
        1000:{
          slidesPerView:5
        },
      }}
      loop={true}
      style={{
        "--swiper-navigation-color": "grey",
        "--swiper-navigation-size": "2rem",
      }}
      navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <img src={marco} alt="rizzi-profile" />
          <h3>Marco Rizzi</h3>
          <p>Presidente</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={eduarda} alt="eduarda-profile" />
          <h3>Eduarda Gonzaga</h3>
          <p>Vice-presidente</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={otto} alt="otto-profile" />
          <h3>Otto Coutinho</h3>
          <p>Vendas / Comercial</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={heitor} alt="heitor-profile" />
          <h3>Heitor Prudente</h3>
          <p>Marketing</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={clara} alt="clara-profile" />
          <h3>Clara Mohammad</h3>
          <p>Administração / Finanças</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={matheus} alt="matheus-profile" />
          <h3>Matheus</h3>
          <p>Pessoas / RH</p>
          </SwiperSlide>
      </Swiper>
    </>
      </div>
      <div className="title">
        <h2>Membros</h2>
      </div>
      <>
      <Swiper 
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 3,
        },
        865:{
          slidesPerView:4
        },
        1000:{
          slidesPerView:5
        },
      }}
      style={{
        "--swiper-navigation-color": "grey",
        "--swiper-navigation-size": "2rem",
      }}
      loop={true}
      navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <img src={jean} alt="imagem 1" />
          <h3>Jean Rothstein</h3>
          <p>Recursos Humanos</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={lucas} alt="imagem 1" />
          <h3>Lucas Henrique</h3>
          <p>Desenvolvimento</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={cecilia} alt="imagem 1" />
          <h3>Cecília</h3>
          <p>Gerência de Projetos</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={luigi} alt="imagem 1" />
          <h3>Luigi</h3>
          <p>Desenvolvimento</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={goulart} alt="imagem 1" />
          <h3>Thiago Goulart</h3>
          <p>Desenvolvimento</p>
          </SwiperSlide>
          <SwiperSlide>
          <img src={pelinsari} alt="imagem 1" />
          <h3>Gabriel Pelinsari</h3>
          <p>Desenvolvimento</p>
          </SwiperSlide>
      </Swiper>
    </>
    </div>
    </div>
  );
}