import styles from './styles.module.scss'
import imagem from '../../assets/contato.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


function Interest() {

    AOS.init();

    return (
      <div className={styles.body} data-aos="fade-up">

        <div className={styles.picture}>
            <img src={imagem} alt="Imagem" className={styles.img} />
        </div>
        <div className={styles.content}>
            <div>
                <h1 className={styles.title}>Se interessou?</h1>
            </div>
            <div className={styles.text}>
                <p>
                    Cadastre-se no nosso banco de talentos, e receba atualizações em primeira mão!
                </p>
            </div>
            <div className={styles.button}>
                <button className={styles.btn}>
                    <a href="https://forms.gle/q1ZqYjvFuPKc2AxZ6" target="_blank" rel="noreferrer">Entrar no banco de talentos</a>
                </button>
            </div>
        </div>
  
      </div>
    );
  }
  
  export default Interest;