import styles from './styles.module.scss'
import iconeBoot from '../../assets/produtosIcones/bootcamp.svg'
import iconeAtividades from '../../assets/produtosIcones/atividade.svg'
import iconeSistemas from '../../assets/produtosIcones/sistema.svg'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


function Produtos() {

    AOS.init();
    return (
        <div className = {styles.container}  data-aos="fade-up">
            <h1 className = {styles.textProdutos}>Produtos</h1>
            <div className = {styles.produtos}>

                <div className = {styles.prodItem}>
                    <img src = {iconeBoot} alt = 'imagem bootcamp' className = {styles.iconeProduto}/>
                    <h2 className = {styles.tituloIcone}>Sites e sistemas</h2>
                    <p className = {styles.textoIcone}>
                        Sites e sistemas personalizados para as suas necessidades e demandas de negócio.
                    </p>                
                </div>

                <div className = {styles.prodItem}>
                    <img src = {iconeAtividades} alt = 'imagem bootcamp' className = {styles.iconeProduto}/>
                    <h2 className = {styles.tituloIcone}>Análise de dados</h2>
                    <p className = {styles.textoIcone}>
                        Análise e desenvolvimento dos dados da sua empresa para impulsionar seus resultados.
                    </p>
                </div>

                <div className = {styles.prodItem}>
                    <img src = {iconeSistemas} alt = 'imagem bootcamp' className = {styles.iconeProduto}/>
                    <h2 className = {styles.tituloIcone}>Remodelagem de sites e sistemas</h2>
                    <p className = {styles.textoIcone}>
                        Otimizações e melhorias dos sistemas internos da sua empresa, a fim de gerar mais resultados.
                    </p>
                </div>

            </div>
        </div>
    );
  }
  
  export default Produtos;