import styles from './styles.module.scss'
import Image from '../../assets/people.JPG'
import Vision from '../../assets/vision.svg'
import Mission from '../../assets/mission.svg'
import Values from '../../assets/values.svg'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function About() {

    AOS.init();

    return (
        <div className={styles.about} data-aos="fade-up">
            <div className={styles.title}>
                <p>Sobre nós</p>
            </div>
            <div className={styles.content}>
                <div className={styles.picture}>
                    <img src={Image} alt="Inteli Jr." className={styles.image}/>
                </div>
                <div className={styles.text}>
                    <p>
                        A <strong>Inteli Jr.</strong> iniciou suas atividades em 2023, sediada no campus do IPT, em São Paulo.
                        A empresa tem como principal objetivo desenvolver soluções tecnológicas através da combinação de habilidades
                        em tecnologia, UX e planejamento estratégico em negócios, com o intuito de criar soluções que agreguem valor
                        ao cliente e causem impacto na sociedade.
                    </p>
                </div>
            </div>
            <div className={styles.topics}>
                <div className={styles.topicStyle}>
                    <div className={styles.topicTitle}>
                        <img src={Mission} alt="Missão" className={styles.topicImage}/>
                        <h1 className={styles.titleTop}>Missão</h1>
                    </div>
                    <p className={styles.texto}>Transformar processos empresariais por meio do digital.</p>
                </div>
                <div className={styles.topicStyle}>
                    <div className={styles.topicTitle}>
                        <img src={Vision} alt="Visão" className={styles.topicImage}/>
                        <h1 className={styles.titleTop}>Visão</h1>
                    </div>
                    <p className={styles.texto}>Nos tornarmos referência em tecnologia nos primeiros 5 anos da empresa.</p>
                </div>
                <div className={styles.topicStyle}>
                    <div className={styles.topicTitle}>
                        <img src={Values} alt="Valores" className={styles.topicImage}/>
                        <h1 className={styles.titleTop}>Valores</h1> 
                    </div>
                    <p className={styles.texto}>Inovação, protagonismo, paixão por tecnologia, responsabilidade.</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default About;