import styles from './styles.module.scss'
import Logo from '../../assets/svg/navlogo.svg'
import miniLogo from '../../assets/svg/miniLogo.svg'
import III from '../../assets/svg/III.svg'
import { useState } from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function Navbar() {

        AOS.init();

        const [sidebar, setSidebar] = useState(false);

        const handleHambClick = () => {
                setSidebar(!sidebar);
        }

        const handleLinkClick = () => {
                setSidebar(false);
            };

        return (
                <div className={styles.navbar}>
                        <div className={styles.logo}>
                                <a href="/"><img src={Logo} alt="logo" /></a>
                        </div>
                        <div className={styles.logo2}>
                                <a href="/"><img src={miniLogo} alt="logo" /></a>
                        </div>
                        <div className={styles.hamb} onClick={handleHambClick}>
                                <img src={III} alt="logo" />
                        </div>
                        <div className={styles.navbarLinks}>
                                <p><a href="/">Home</a></p>
                                <p><a href="/#produtos">Produtos</a></p>
                                <p><a href="/#sobre">Sobre Nós</a></p>
                                <p><a href="/#cases">Cases</a></p>
                                <p><a href="/#contato">Contato</a></p>
                                <p><a href="https://docs.google.com/forms/d/e/1FAIpQLScseD6W0DMFTCA22txXpQ5Hyx0mX2IXdZk7fNzrf7gNHy3eKw/viewform">Inscrever-se</a></p>

                        </div>
                        {sidebar ? <div className={styles.sidebar} data-aos="fade-left">
                                <p><a href="/">Home</a></p>
                                <p><a href="/#produtos" onClick={handleLinkClick}>Produtos</a></p>
                                <p><a href="/#sobre" onClick={handleLinkClick}>Sobre Nós</a></p>
                                <p><a href="/#cases" onClick={handleLinkClick}>Cases</a></p>
                                <p><a href="/#contato" onClick={handleLinkClick}>Contato</a></p>
                                <p><a href="https://docs.google.com/forms/d/e/1FAIpQLScseD6W0DMFTCA22txXpQ5Hyx0mX2IXdZk7fNzrf7gNHy3eKw/viewform" onClick={handleLinkClick}>Inscrever-se</a></p>

                        </div> : ""}
                </div>
        );
}


export default Navbar;