import React from 'react';
import styles from './styles.module.scss';
import imagem1 from '../../assets/porqueicones/Component10.png'
import imagem2 from '../../assets/porqueicones/Component12.png'
import imagem3 from '../../assets/porqueicones/Component11.png'
import imagem4 from '../../assets/porqueicones/Component13.png'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles



function Porquee() {

    AOS.init();
    return (
        <div className={styles.container} data-aos="fade-up">
            <h1 className={styles.titulo}>Por que se juntar à Inteli Júnior?</h1>

            <div className={styles.conteudo}>
                <div className={styles.item}> 
                    <img src={imagem1} alt="Icone 2" className={styles.elipse} />
                    <div className={styles.texto}>
                        <p>Experiência empresarial</p>
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.elipse}>
                        <       img src={imagem2} alt="Icone 1" className={styles.imagem} />
                    </div>
                    <div className={styles.texto}>
                        <p>Oportunidade de colocar conhecimentos em prática</p>
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.elipse}>
                        <       img src={imagem3} alt="Icone 1" className={styles.imagem} />
                    </div>
                    <div className={styles.texto}>
                        <p>Aprendizagem técnica e de negócios</p>
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.elipse}>
                        <       img src={imagem4} alt="Icone 1" className={styles.imagem} />
                    </div>
                    <div className={styles.texto}>
                        <p>Networking e rede imparável de EJs de todo o Brasil</p>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Porquee;
