import styles from './styles.module.scss'
import { useNavigate } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function Talentos(){

    AOS.init();

    let navigate = useNavigate(); 
const routeChange = () =>{ 
  let path = `/students`; 
  window.scrollTo(0, 0);
  navigate(path);
}
    return(
        <div className = {styles.container} data-aos="fade-up">
            <p className = {styles.text}>É aluno e quer trabalhar com a gente?</p>
            <div id = "cases">
            </div>
            <button className = {styles.button} onClick={routeChange}>Entrar no banco de talentos</button>
        </div>
    )
}

export default Talentos