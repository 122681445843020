import Footer from "../../components/footer/Footer";
import styles from './styles.module.scss'
import Produtos from "../../components/produtos/Produtos";
import Navbar from "../../components/navbar/Navbar"
import Solucao from '../../components/solucoes/Solucao';
import Talentos from '../../components/talentos/Talentos';
import Contato from '../../components/contato/Contato';
import Intro from "../../components/intro/intro";
import About from "../../components/about/About";
import Cases from "../../components/cases/cases";
import Banner from "../../components/banner/Banner";

function Home() {
    return (
      <div className={styles.home}>
        <div className={styles.navPai}>
          <Navbar></Navbar>
        </div>
        
        <div className={styles.homeContent}>
          {/* <Intro></Intro> */}
          <Banner></Banner>
          <Produtos></Produtos>
          <Solucao></Solucao>
          <About></About>
          <Talentos></Talentos>
          <Cases></Cases>
          <Contato></Contato>
        </div>
        <div className={styles.footerPai}>
          <Footer></Footer>
        </div>

      </div>
    );
  }
  
  export default Home;