import styles from './styles.module.scss'
import { useNavigate } from "react-router-dom";


import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function Solucao(){


    return(
        <div className = {styles.container} data-aos="fade-up">
            <p className = {styles.text}>Tem interesse nas nossas soluções?</p>
            <div id = "sobre">
            </div>
            <a href = '/#contato'>
                <button className = {styles.button}>Agende uma avaliação gratuita!</button>
            </a>

        </div>
    )
}

export default Solucao