import styles from "./styles.module.scss"
import IntroImage from "../../assets/introImage"
import IntroBack from '../../assets/introBack'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function Intro() {

    AOS.init();

    const caminhoDoSVG = '../../assets/svg/introBack.svg';

    const estiloDoComponente = {
        backgroundImage: `url(${caminhoDoSVG})`
      };

    return (
      <div className={styles.introcomp} style={estiloDoComponente} data-aos="fade-up">
        {/* <div className={styles.introbackground}>
        <IntroBack></IntroBack>
        </div> */}
        {/* <div style={estiloDoComponente}>
        </div> */}
        
        <div className={styles.introcomp1}>
            <h1>Procurando por soluções tecnológicas e inovadoras?</h1>
            <p>Nós, da Inteli Jr, oferecemos produtos inovadores a preços acessíveis para a sua empresa. </p>
            <button>Entre em contato!</button>
        </div>
        {/* <div className={styles.introcomp2}>
            <IntroImage></IntroImage>
        </div> */}
        
      </div>
    );
  }
  
  export default Intro;
  