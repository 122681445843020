import styles from './styles.module.scss'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


function Banner() {

  AOS.init();

    return (
    <div className={styles.container} data-aos="fade-in">
      <div className={styles.buttonBox}> 
      
      <h1 className={styles.titulo}>
        Procurando por soluções tecnológicas e inovadoras?
      </h1>
        <p className={styles.paragrafo}>
        Nós, da Inteli Jr, oferecemos produtos inovadores a preços acessíveis para a sua empresa. 
        </p>
      <a href="/#contato">
        <button className={styles.button}>Entre em contato!</button>
      </a>

      <div id = "produtos">
      </div>

      </div>

    </div>
    
    )
  }
  
  export default Banner;