import styles from './styles.module.scss'
import imagem1 from '../../assets/areasicones/Component23.png'
import imagem2 from '../../assets/areasicones/Component24.png'
import imagem3 from '../../assets/areasicones/Component25.png'
import imagem4 from '../../assets/areasicones/Component26.png'
import imagem5 from '../../assets/areasicones/Component27.png'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


function Areas(){

    AOS.init();
    return(
        <div className = {styles.container} data-aos="fade-up">
            <h1>Áreas internas</h1>

            <div className= {styles.conteudo}>
                <div className={styles.item}>
                    <img src = {imagem1} alt = 'imagem 1' className={styles.icone}/>
                    <h2>Financeiro</h2>
                    <p className={styles.texto}>Gerencia nossos orçamentos, pagamentos e alocação de recursos</p>
                </div>

                <div className={styles.item}>
                    <img src = {imagem2} alt = 'imagem 1' className={styles.icone}/>
                    <h2>Marketing</h2>
                    <p className={styles.texto}>Gerencia nossas redes sociais e nossa marca</p>

                </div>

                <div className={styles.item}>
                    <img src = {imagem3} alt = 'imagem 1' className={styles.icone}/>
                    <h2>Pessoas</h2>
                    <p className={styles.texto}>Cuida dos colaboradores e eventos</p>

                </div>
            </div>

            <div className= {styles.conteudo2}>
                <div className={styles.item}>
                    <img src = {imagem4} alt = 'imagem 1' className={styles.icone}/>
                    <h2>Projetos</h2>
                    <p className={styles.texto}>Desenvolve nossas soluções tecnológias na prática</p>
                </div>

                <div className={styles.item}>
                    <img src = {imagem5} alt = 'imagem 1' className={styles.icone}/>
                    <h2>Vendas</h2>
                    <p className={styles.texto}>Tem contato direto com nossos clientes e parceiros</p>

                </div>
            </div>





        </div>
    )
}

export default Areas